import JsBarcode from 'jsbarcode' 
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_pdf_kal_fonts_bn'

const exportLabelDetails = async (base64Logo, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    pdfMake.fonts = {
      Kalpurush: {
        normal: 'Kalpurush.ttf',
        bold: 'Kalpurush.ttf',
        italics: 'Kalpurush.ttf',
        bolditalics: 'Kalpurush.ttf'
      }
    }

    var pdfContent = []
    const date = new Date()
    const pageBreak = vm.length > 1 ? 'after' : ''
    
    vm.forEach(item => {
      
        var singlePos = [
            { image: 'data:image/jpg;base64,' + base64Logo, height: 60, width: 100,  style: 'logo', alignment: 'center' },
            // { text: 'BAHOK COURIER LIMITED', style: 'poshead', alignment: 'center' },

            { image: 'data:image/' + generateBarcode(item.id), alignment: 'center' },
            { text: item.tracking_id + (item.exchange == 1 ? ' | Exchange' : ''), alignment: 'center', style: 'cod' },
            { text: (item.destination ? item.destination.name : '') + ' : ' + 'TK ' + item.price , alignment: 'center', style: 'cod' },
            { text: item.thana.name + ', ' + item.district.name , alignment: 'center', style: 'cod' },

            { text: item.name, alignment: 'left', style: 'td' },
            { text: item.mobile, alignment: 'left', style: 'td' },
            { text: item.address, alignment: 'justify', style: 'td' },
            { text: Store.state.authUser.name +' | www.bahokcourier.com | ' + date.toDateString() + ' at '+ date.toLocaleTimeString(), alignment: 'center', style: 'website', pageBreak: pageBreak }
        ]
        pdfContent.push(singlePos)
    })
    
    var docDefinition = {
      content: pdfContent,
      pageSize: {
        width: 192, // 2 inch
        height: 288 // 3 inch
      },
      defaultStyle: {
        font: 'Kalpurush'
      },
      dontBreakRows: true,
      pageMargins: [8, 0, 8, 0],
      styles: {
        td: {
          fontSize: 10,
          margin: [0, 2, 2, 0]
        },
        cod: {
          fontSize:  13,
          margin: [2, 1, 0, 1]
        },
        district: {
          fontSize:  15,
          margin: [2, 1, 0, 1]
        },
        website: {
          fontSize: 8,
          margin: [0, 8, 0, 0]
        },
        poshead: {
          fontSize: 10,
          margin: [0, 0, 0, 10]
        },
        logo: {
          margin: [0, 0, 0, 0]
        }
      }
    }
    // var win = window.open('', '_blank')
    // pdfMake.createPdf(docDefinition).open({}, win)
    pdfMake.createPdf(docDefinition).print()
  } catch (error) {
    if (error) {
      console.log('err => ', error)
    }
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}

function generateBarcode (orderNo) {
  let canvas = document.createElement('canvas');
  JsBarcode(canvas, orderNo, { 
      format: 'CODE39',
      displayValue: false,
      height: 22,
      width: 1.30,
      padding: 0,
      margin: 0
  });
  return canvas.toDataURL('image/png');
}

export default {
    exportLabelDetails
}
